const calculateTimeConstance = (C, R) => {
  return C * R
}

const Vc = (emf, t, RC) => emf * (1 - Math.exp(-t / RC))

const A = (R, emf, t, RC) => (emf / R) * Math.exp(-t / RC)

export const calculateGraphPoint = (emf, C, R, T) => {
  console.log('T in cal', T)
  const RC = calculateTimeConstance(C, R)
  const datas = {
    V: [],
    Q: [],
    A: [],
    percentage: []
  }
  for (let t = 0; t <= RC * T; t += (RC * 10) / 100) {
    const x = roundToTwoDecimal(t / RC)
    const v = Vc(emf, t, RC)
    datas.Q.push({ x, y: roundToTwoDecimal(v * C) })
    datas.V.push({ x, y: roundToTwoDecimal(v) })
    datas.percentage.push({ x, y: roundToTwoDecimal((v / emf) * 100) })
    datas.A.push({ x, y: roundToTwoDecimal(A(R, emf, t, RC)) })
  }

  return datas
}

const roundToTwoDecimal = num => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}
