import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import DecorationLeft from "../Assets/decoration-home-bg-left.svg";
import DecorationRight from "../Assets/decoration-home-bg-right.svg";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="decoration-left">
        <img src={DecorationLeft} alt="" />
      </div>
      <div className="decoration-right">
      <img src={DecorationRight} alt="" />
      </div>
      <div className="homepage-container">
        <div className="homepage-div">
          <h1 className="header">RC Circuit</h1>
          <h1 className="semitext">Created by Wanjai and Mr.Physics Team</h1>
          <button className="button" onClick={() => navigate("/simulation")}>
            Get Started
          </button>
        </div>
      </div>
    </Layout>
  );
};
export default HomePage;
