import { useEffect, useState } from 'react'

import Navbar from './navbar'
const Layout = props => {
  const pathname = window.location.pathname

  return (
    <div
      className={
        pathname === '/simulation'
          ? `background-h-230`
          : pathname === '/team'
          ? `background-h-150`
          : `background`
      }
    >
      <div className="center">
        <Navbar />
      </div>
      <div className="container-layout">{props.children}</div>
    </div>
  )
}
export default Layout
