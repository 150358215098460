import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import icon from '../Assets/icon.png'
const Navbar = () => {
  const pathname = window.location.pathname
  const navigate = useNavigate()
  const [isOnTop, setIsOnTop] = useState(false)
  window.onscroll = function () {
    if (window.pageYOffset === 0) {
      setIsOnTop(true)
    } else {
      setIsOnTop(false)
    }
  }

  const componentNav = [
    { label: 'How We Work', link: '/', page: 'How We Work' },
    { label: 'Simulation', link: '/simulation', page: 'Simulation' }
  ]
  return (
    <div className={isOnTop ? 'container-nav container-nav-top' : 'container-nav'}>
      <div className="nav">
        <div className="flex-row">
          <img src={icon} alt="icon" width={23} height={22.4} />
          <div className="nav-title">Wanjai and Mr.Physics</div>
        </div>
        <div className="nav-div">
          {componentNav.map(item => {
            return (
              <div
                className={
                  pathname === item.link ? 'nav-item-active' : 'nav-item-inactive'
                }
                onClick={() => navigate(item.link)}
              >
                {item.label}
              </div>
            )
          })}
        </div>
        <div className="nav-button" onClick={() => navigate('/team')}>
          Teams
        </div>
      </div>
    </div>
  )
}
export default Navbar
