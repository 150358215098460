import Layout from "../components/Layout";
import Note from "../Assets/note.png";
import Z from "../Assets/z.png";
import Tar from "../Assets/tar.png";
import Porju from "../Assets/porju.png";
import Ching from "../Assets/ching.png";

const TeamPage = () => {
  return (
    <Layout>
      <div className="team-container">
        <div className="homepage-div">
          <div className="semihead team-subtitle">The team</div>
          <div className="header">Behind</div>
          <div className="header">Wanjai and Mr.Physics</div>
          <div className="team-div">
            <MemberCard
              image={Note}
              name="Thanakorn Aungkunchuchod"
              id="62130500209"
              position="Founder & CEO"
            />
            <MemberCard
              image={Z}
              name="Sethanant Pipatpakorn"
              id="62130500230"
              position="Founder & CEO"
            />
            <MemberCard
              image={Tar}
              name="Nataphon Pungsesai"
              id="62130500236"
              position="Founder & CEO"
            />
            <MemberCard
              image={Porju}
              name="Nuttavadee Autsavapanakit"
              id="62130500237"
              position="Founder & CEO"
            />
            <MemberCard
              image={Ching}
              name="Kavisara Srisuwatcharee"
              id="62130500254"
              position="Founder & CEO"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default TeamPage;

function MemberCard(props) {
  return (
    <div>
      <img src={props.image} className="team-image" alt="pic" />
      <h1 className="team-name">{props.name}</h1>
      <h2 className="team-id">{props.id}</h2>
      <h3 className="team-description">{props.position}</h3>
    </div>
  );
}
