import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import ProjectPage from "./pages/projectPage";
import SimulationPage from "./pages/simulationPage";
import TeamPage from "./pages/teamPage";
import "./styles/App.css";
import "./styles/HomePage.css";
import "./styles/Team.css";

function App() {
  // const Login = lazy(() => import("./Page/Login"));

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/projects" element={<ProjectPage />} /> */}
        <Route path="/simulation" element={<SimulationPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/team" element={<TeamPage />} />
        {/* <Route path="*" element={<HomePage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
