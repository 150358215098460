import React, { useState, useRef, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { calculateGraphPoint } from '../RC'
import '../styles/App.css'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/system'
import { Equation } from 'react-equation'
import Layout from '../components/Layout'
import Circuit from '../Assets/circuit.svg'

const SimulationPage = props => {
  const [V, setV] = useState(10)
  const [C, setC] = useState(1)
  const [R, setR] = useState(10)
  const [T, setT] = useState(4)
  const [data, setData] = useState([])
  const [value, setValue] = useState(30)
  const myRef = useRef(null)
  const marks = [
    {
      value: 0,
      label: '0T'
    },
    {
      value: 1,
      label: '1T'
    },
    {
      value: 2,
      label: '2T'
    },
    {
      value: 3,
      label: '3T'
    },
    {
      value: 4,
      label: '4T'
    },
    {
      value: 5,
      label: '5T'
    },
    {
      value: 6,
      label: '6T'
    },
    {
      value: 7,
      label: '7T'
    },
    {
      value: 8,
      label: '8T'
    },
    {
      value: 9,
      label: '9T'
    },
    {
      value: 10,
      label: '10T'
    }
  ]

  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: '#FFFFFF', //color of the slider between thumbs
    '& .MuiSlider-thumb': {
      color: '#FFFFFF'
    },
    '& .MuiSlider-markLabel': {
      color: '#FFFFFF'
    },
    '& .MuiSlider-rail': {
      background: '#FFFFFF'
    },
    '& .MuiSlider-track': {
      background: 'linear-gradient(#7692FF, #012754)'
    }
  }))
  function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function valueText(value) {
    return <h1 className="text-white">{value}</h1>
  }
  const inputRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
  const onSubmit = () => {
    if (C === '' || R === '' || V === '' || T === '') return
    setT(4)
    setData(calculateGraphPoint(V, C, R, T))
    executeScroll('graph')
  }

  useEffect(() => {
    if (C === '' || R === '' || V === '' || T === '') return
    setData(calculateGraphPoint(V, C, R, T))
  }, [T])

  return (
    <Layout>
      <div className="page">
        <div className="container">
          <div className="flex-col">
            <div className="simulation-glassbox w-[557px] h-[300px]">
              <img src={Circuit} alt="pic" className="mgb-10" />
            </div>
            <div className="simulation-glassbox formular text-white bg-white px-[32px] py-[64px]">
              <Equation value="τ = RC" />
              <Equation value="Vc = ε ( 1-e^ ( -t/RC ) )" style={{ fill: 'white' }} />
              <Equation value="i = ( ε/R ) e^ ( -t/RC )" style={{ fill: 'white' }} />
              <Equation value="q = Cε( 1-e^ ( -t/RC ) )" style={{ fill: 'white' }} />
            </div>
          </div>
          <div className="flex-col pv-25">
            <div className="simulation-input-title">Input</div>
            <div>
              <div className="simulation-input-label">Voltage (V)</div>
              <input
                placeholder="Please enter Voltage..."
                type="tel"
                onChange={e => setV(e.target.value)}
                className="input"
                value={V}
              />
            </div>
            <div>
              <div className="simulation-input-label">Capacitance (C)</div>
              <input
                placeholder="Please enter Capcitance..."
                type="tel"
                onChange={e => setC(e.target.value)}
                className="input"
                value={C}
              />
            </div>
            <div>
              <div className="simulation-input-label">Load Resistance (R)</div>
              <input
                placeholder="Please enter Load Resistance..."
                type="tel"
                onChange={e => setR(e.target.value)}
                className="input"
                value={R}
              />
            </div>
            <button className="button" onClick={onSubmit}>
              Calculate
            </button>
          </div>
          {/* <div className="container-chart">
          <Line options={options} data={data} />
        </div> */}
        </div>
        <div ref={myRef} className="simulation-container">
          <div className="semihead">How The Result</div>

          <div className="simulation-output-header">Graph</div>
          <div className="simulation-output-container">
            <ChartBox
              data={data.percentage}
              lineName="Percentage"
              label="Percentage of charge in capacitor (%)"
            />
            <ChartBox
              data={data.Q}
              lineName="Charge"
              label="Total charge in capacitor (q)"
            />
            <ChartBox data={data.V} lineName="Voltage" label="Voltage at capacitor (V)" />
            <ChartBox data={data.A} lineName="Current" label="Current at capacitor (A)" />
          </div>
          <div className="text-white">
            <Box sx={{ width: '100%' }}>
              <CustomSlider
                max={10}
                min={0}
                defaultValue={T}
                aria-labelledby="discrete-slider-restrict"
                onChange={({ target }) => setT(target.value)}
                valueLabelDisplay="auto"
                size="big"
                marks={marks}
              />
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default SimulationPage

function ChartBox(props) {
  return (
    <div>
      <div className="simulation-graph-label">{props.label}</div>
      <div className="simulation-glassbox graph">
        <div className="container-chart">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={props}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                axisLine={false}
                type="number"
                allowDataOverflow={true}
                ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                domain={['dataMin', 'dataMax']}
                tickFormatter={t => `${t} RC`}
              />
              <YAxis
                className="yAxis"
                axisLine={false}
                // domain={[0, 100]}
                // tickFormatter={(t) => `${t}%`}
              />
              <Tooltip />
              <Legend />
              <Line
                isAnimationActive={true}
                dataKey="y"
                data={props.data}
                name={props.lineName}
                stroke="#8884d8"
                // activeDot={{ r: 8 }}
                strokeWidth={2}
                dot={false}
              />
              {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
